import { render, staticRenderFns } from "./ThreeDImaging.vue?vue&type=template&id=9ff01d46&scoped=true&"
import script from "./ThreeDImaging.vue?vue&type=script&lang=js&"
export * from "./ThreeDImaging.vue?vue&type=script&lang=js&"
import style0 from "./ThreeDImaging.vue?vue&type=style&index=0&id=9ff01d46&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ff01d46",
  null
  
)

export default component.exports