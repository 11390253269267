<template>
    <div>
        <h3>Bug Report Form</h3>
      <a href="https://iu.co1.qualtrics.com/jfe/form/SV_1Tb7v5OXrBISZCt" target="_blank">Open form in separate window</a>
      <br />
        <iframe src="https://iu.co1.qualtrics.com/jfe/form/SV_1Tb7v5OXrBISZCt" width="100%" height="2000" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
</template>

<script>
    export default {
        name: "Report a Bug"
    }
</script>

<style scoped>

</style>